import React, { memo } from "react";
import Box from "../../Components/Box";
import AppLayout from "../../Components/layout/AppLayout";
import Navbar from "../../Components/navbar/Navbar";
import useWindowSize from "../../hooks/useWindowSize";
import imgPath from './404.png'
import FlexBox from "../../Components/FlexBox";
import Image from "../../Components/Image";
import { Link } from "@inertiajs/react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { H2 } from "../../Components/Typography";


const Page404 = memo(function Page(props) {

    //console.log(props.page.data.content);
    console.log(imgPath);
    const screenWidth = useWindowSize()

    return (

        <AppLayout navbar={<Navbar />} seo={props?.seo}>
            <FlexBox justifyContent='center' mt={screenWidth > 1980 ? '10rem' : '12rem'} mb={'2rem'} maxHeight='60vh' height={screenWidth > 1980 && '50vh'}>
                <Image src={`${imgPath}`} alt={"cover image"}/>
            </FlexBox>

            <Link href='/'>
                <H2 fontWeight={600} m={5} textAlign='center'> Back to Shop <NavigateNextIcon /> </H2>
            </Link>
        </AppLayout >

    );
  });



  export default Page404;